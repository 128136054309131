import React, { useContext } from "react"
import { RootContext } from "../../RootContext"

/*---------  import CSS ---------*/
import { useJobStylesText, useJobStylesNotes } from "styles/job/jobCss"

/*---------  Pegaso components ---------*/
import DateUtils from "components/Tools/DatesUtils"
import JobsUtils from "components/Utils/JobUtils"
import JobControlUtils from "components/Utils/JobControlUtils"

/*---------  Core components ---------*/
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined"
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import Switch from "@material-ui/core/Switch"
import TextField from "@material-ui/core/TextField"
import Tooltip from '@material-ui/core/Tooltip';
/*************************************************************************
                  COMPONENTI UTILIZZATI PER COMPONENTI SOTTO
 *************************************************************************/

//---------------- TextField del select Tipo Lente
function SelectField(props) {
  const classesText = useJobStylesText() // TEXT
  const {
    id,
    name,
    label,
    helpText,
    value,
    optionList,
    onChange,
    disabled,
    onblur,
    requiredFields,
  } = props
  var isRequired = CellFieldIsRequired(id, requiredFields)
  return (
    <TextField
      select
      error={isRequired ? !value : false}
      required={isRequired}
      id={id}
      label={label}
      className={classesText.textField}
      value={value || ""}
      onBlur={onblur ? onblur(name, (v) => v) : null}
      onChange={onChange(name)}
      disabled={disabled || false}
      helperText={helpText}
      margin="normal"
      SelectProps={{
        MenuProps: {
          className: classesText.menu,
        },
      }}
    >
      {optionList.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

//---------------- TextField con text generica
function CellField(props) {
  const classesText = useJobStylesText() // TEXT
  const {
    id,
    name,
    label,
    value,
    check,
    onChange,
    disabled,
    format,
    onblur,
    requiredFields,
    type,
    helperText,
  } = props
  const result = value ? check(value) : true

  var isRequired = CellFieldIsRequired(id, requiredFields)

  let error = false
  if (result) {
    error = result.error
  }

  return (
    <TextField
      type={type ? type : "text"}
      required={isRequired}
      error={!disabled ? isRequired ? error || !value : error : false}
      id={id}
      label={label}
      className={classesText.textField}
      value={value || ""}
      onChange={onChange(name)}
      onBlur={onblur ? onblur(name, format) : null}
      helperText={error ? result.msgError : helperText ? helperText : ""}
      margin="normal"
      disabled={disabled || false}
    />
  )
}

function CellFieldIsRequired(id, requiredFields) {
  let result = false
  requiredFields.forEach((element) => {
    var posUnderscore = element.indexOf("_")
    var first, second
    if (posUnderscore > 0) {
      first = element.substr(0, posUnderscore)
      second = element.substr(posUnderscore + 1)
      if (id.indexOf(first) >= 0) {
        result = true
      } else if (id.indexOf(second) >= 0) {
        result = true
      }
    } else {
      if (id.indexOf(element) >= 0) {
        result = true
      }
    }
  })
  return result
}

//---------------- TextField con text note
function NotesField(props) {
  const classesText = useJobStylesNotes() // TEXT
  const { id, label, value, onChange, disabled, status } = props
  const name = id

  return (
    <TextField
      multiline
      minRows="2"
      id={id}
      label={label}
      className={status === "ERROR" ? classesText.textFieldError : classesText.textField}
      value={value || ""}
      onChange={onChange(name)}
      margin="normal"
      variant="outlined"
      disabled={disabled || false}
    />
  )
}

/*************************************************************************
                  COMPONENTI VISUALIZZATI IN SCHEDA JOB
 *************************************************************************/

function HeadDataGrid({ headValues, creation, jobId, onChange, onBlur, requiredFields, editMode }) {
  const {
    optician,
    lab,
    company,
    jobnumber,
    date,
    update,
    status,
    insertby,
    updateby,
    deviceUploader,
    deviceDownloader,
  } = headValues
  const { t, getUtc } = useContext(RootContext)

  const notOnlyInsert = date !== update

  return (
    <div>
      <Grid container justifyContent="flex-start" alignItems="center">
        {/* prima riga - la somma degli xs non deve superare 12  */}
        <Grid item xs={3}>
          <p>
            {t("10013").toUpperCase()}: {company.name}
          </p>
        </Grid>
        <Grid item xs={3}>
          <p>
            {t("10060").toUpperCase()}: {lab.id} - {lab.name}
          </p>
        </Grid>
        <Grid item xs={3}>
          <p>
            <strong>
              {t("10092").toUpperCase()}: {optician.id} - {optician.name}
            </strong>
          </p>
        </Grid>
        <Grid item xs={2}>
          {" "}
          {
            <p>
              <strong>
                {t("10050").toUpperCase()}: {creation ? jobId : jobnumber}
              </strong>
            </p>
          }
        </Grid>
        <Grid item xs={1}>
          {creation ? (
            ""
          ) : (
            <SelectField
              id={"status"}
              name={"status"}
              label={t("10129").toUpperCase()}
              helpText={t("10129")}
              value={status}
              optionList={JobsUtils.statusValues()}
              onChange={onChange}
              onBlur={onBlur}
              disabled={!editMode}
              requiredFields={requiredFields}
            />

          )}
        </Grid>


        {/* seconda riga - la somma degli xs non deve superare 12  */}

        {creation ? null : (
          <Grid item xs={4}>
            <p>
              {t("10018").toUpperCase()}: {DateUtils.formatDateWithUtc(date, getUtc())}{" "}
              {insertby}{" "}
            </p>
          </Grid>
        )}
        {creation ? null : (
          <Grid item xs={4}>
            <p>
              {notOnlyInsert
                ? `${t("10021").toUpperCase()}: ${DateUtils.formatDateWithUtc(
                  update,
                  getUtc()
                )} ${updateby}`
                : ""}{" "}
            </p>
          </Grid>
        )}
        {creation ? null : (
          <Grid item xs={2}>
            <p>
              {deviceUploader
                ? `${t("10020").toUpperCase()}: ${deviceUploader.brand} ${deviceUploader.name
                }`
                : ""}
            </p>{" "}
          </Grid>
        )}
        {creation ? null : (
          <Grid item xs={2}>
            <p>
              {deviceDownloader
                ? `${t("10022").toUpperCase()}: ${deviceDownloader.brand} ${deviceDownloader.name
                }`
                : ""}
            </p>{" "}
          </Grid>
        )}
      </Grid>
    </div>
  )
}

function DeviceDataGrid({ jobDevices }) {
  const { type, brand, model } = jobDevices
  const { t } = useContext(RootContext)
  return (
    <div>
      <Grid container item xs={12}>
        <Grid item xs={4} align="center">
          <p>
            {t("10138")} : {type}{" "}
          </p>
        </Grid>
        <Grid item xs={4} align="center">
          <p>
            {t("10009")} : {brand}{" "}
          </p>
        </Grid>
        <Grid item xs={4} align="center">
          <p>
            {t("10075")} : {model}{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

// Componente per i dati della lente destra/sinistra
function LensDataGrid({
  edgingDetails,
  editMode,
  onChange,
  onblur,
  handleClone,
  active,
  onChangeActive,
  requiredFields,
  personalized,
}) {
  const {
    side,
    ltyp,
    hbox,
    dia,
    cyl,
    sph,
    ax,
    add,
    ipd,
    lmattype,
    ocht,
    vbox,
    _lcoat,
    thkp,
    cthick,
    fcrv,
    frnt,
    ztilt,
    lind,
    _seght,
    panto,
    bvd,
  } = edgingDetails
  const { t, getLensTypes, getEdgingEightType, getCustomProductPersonalized } = useContext(RootContext)
  var edgingEightType = getEdgingEightType()
  var customProductPersonalized = getCustomProductPersonalized()

  return (
    <div>
      <Grid container justifyContent="space-between">
        {side === "R" ? (
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Switch
                disabled={!editMode}
                edge="start"
                onChange={onChangeActive}
                checked={active}
              />
            </Grid>
            <Grid item xs={4}>
              <p align="center">
                <u>{t("10111").toUpperCase()} </u>
              </p>
            </Grid>
            <Grid item xs={4}>
              <p align="right">
                <u>
                  <IconButton
                    disabled={!editMode}
                    variant="outlined"
                    color="secondary"
                    onClick={handleClone}
                  >
                    <ArrowForwardOutlinedIcon />
                  </IconButton>
                </u>
              </p>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <p align="left">
                <u>
                  <IconButton
                    disabled={!editMode}
                    variant="outlined"
                    color="secondary"
                    onClick={handleClone}
                  >
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                </u>
              </p>
            </Grid>
            <Grid item xs={4}>
              <p align="center">
                <u>{t("10065").toUpperCase()} </u>
              </p>
            </Grid>
            <Grid item xs={4}>
              <p align="right">
                <u>
                  <Switch
                    disabled={!editMode}
                    edge="start"
                    onChange={onChangeActive}
                    checked={active}
                  />
                </u>
              </p>
            </Grid>
          </Grid>
        )}
        {active ? (
          <Grid container justifyContent="space-between">
            {
              /*  RIGA 1  
                  tipo lente - diametro
              */
            }
            <Grid item xs={6}>
              <SelectField
                id={`ltyp_${side}`}
                name={`ltyp`}
                label={t("10070")}
                helpText={t("10127")}
                value={ltyp}
                optionList={getLensTypes()}
                onChange={onChange}
                disabled={!editMode}
                onblur={onblur}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={6}>
              <CellField
                id={`dia_${side}`}
                name={`dia`}
                label={t("10028")}
                value={dia}
                check={(v) => JobControlUtils.controlDiameter(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>

            {
              /*  RIGA 2  
                  materiale lente - scivolosità lente
              */
            }
            <Grid item xs={3}>
              <SelectField
                id={`lmattype_${side}`}
                name={`lmattype`}
                label={t("10300")}
                helpText={t("10309")}
                value={lmattype}
                optionList={JobsUtils.getMaterialLensTypes()}
                onChange={onChange}
                disabled={!editMode}
                onblur={onblur}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3} >
              <SelectField
                id={`lind_${side}`}
                name={`lind`}
                label={t("10403")}
                helpText={t("10404")}
                value={lind}
                optionList={JobsUtils.getLensIndex()}
                onChange={onChange}
                disabled={!editMode}
                onblur={onblur}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                id={`_lcoat_${side}`}
                name={`_lcoat`}
                label={t("10301")}
                helpText={t("10310")}
                value={_lcoat}
                optionList={JobsUtils.getSlipperyLensTypes()}
                onChange={onChange}
                disabled={!editMode}
                onblur={onblur}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3} />
            {
              /*  RIGA 3  
                  sfero - cilindro - asse - addizione
              */
            }
            <Grid item xs={3}>
              <CellField
                id={`sph_${side}`}
                name={`sph`}
                label={t("10128")}
                value={sph}
                check={(v) => JobControlUtils.controlSphere(v, t)}
                onChange={onChange}
                format={JobsUtils.formatSphereCylinder}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3}>
              <CellField
                id={`cyl_${side}`}
                name={`cyl`}
                label={t("10017")}
                value={cyl}
                check={(v) => JobControlUtils.controlCylinder(v, t)}
                onChange={onChange}
                format={JobsUtils.formatSphereCylinder}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            <Tooltip title={t("10246")} aria-label={t("10246")}>
              <Grid item xs={3}>
                <CellField
                  id={`ax_${side}`}
                  name={`ax`}
                  label={t("10004")}
                  value={ax}
                  check={(v) => JobControlUtils.controlAxis(v, t)}
                  onChange={onChange}
                  format={(v) => v}
                  onblur={onblur}
                  disabled={!editMode}
                  requiredFields={requiredFields}
                />
              </Grid>
            </Tooltip>
            <Grid item xs={3}>
              <CellField
                id={`add_${side}`}
                name={`add`}
                label={t("10000")}
                value={add}
                check={(v) => JobControlUtils.controlAddition(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            {
              /*  RIGA 4
                  spessore bordo - spessore centro - curva montatura - curva base
              */
            }
            <Grid item xs={3}>
              <CellField
                id={`thkp_${side}`}
                name={`thkp`}
                label={t("10303") + " (mm)"}
                value={thkp}
                check={(v) => JobControlUtils.controlTickestEdge(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3}>
              <CellField
                id={`cthick_${side}`}
                name={`cthick`}
                label={t("10304") + " (mm)"}
                value={cthick}
                check={(v) => JobControlUtils.controlTickestEdge(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>

            <Grid item xs={3}>
              <CellField
                id={`fcrv_${side}`}
                name={`fcrv`}
                label={t("10400")}
                value={fcrv}
                check={(v) => JobControlUtils.controlFcrv(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3}>
              <CellField
                id={`frnt_${side}`}
                name={`frnt`}
                label={t("10401")}
                value={frnt}
                check={(v) => JobControlUtils.controlFcrv(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>

            {
              /*  RIGA 5  
                  distanza interpupillare - altezza montaggio - dima orizzontale - dima verticale
              */
            }
            <Grid item xs={3}>
              <CellField
                id={`ipd_${side}`}
                name={`ipd`}
                label={t("10049")}
                value={ipd}
                check={(v) => JobControlUtils.controlPd(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={!editMode}
                requiredFields={requiredFields}
              />
            </Grid>
            {
              edgingEightType === "BOX" ? (
                <Grid item xs={3}>
                  <CellField
                    id={`ocht_${side}`}
                    name={`ocht`}
                    label={t("10088")}
                    value={ocht}
                    check={(v) => JobControlUtils.controlEdgingHeight(v, t)}
                    onChange={onChange}
                    format={JobsUtils.formatGeneric}
                    onblur={onblur}
                    disabled={!editMode}
                    requiredFields={requiredFields}
                    helperText={t("10389")}
                  />
                </Grid>
              ) : (
                <Grid item xs={3}>
                  <CellField
                    id={`_seght_${side}`}
                    name={`_seght`}
                    label={t("10088")}
                    value={_seght}
                    check={(v) => JobControlUtils.controlEdgingHeight(v, t)}
                    onChange={onChange}
                    format={JobsUtils.formatGeneric}
                    onblur={onblur}
                    disabled={!editMode}
                    requiredFields={requiredFields}
                    helperText={t("10409")}
                  />
                </Grid>
              )
            }
            <Grid item xs={3}>
              <CellField
                id={`hbox_${side}`}
                name={`hbox`}
                label={t("10043")}
                value={hbox}
                check={(v) => JobControlUtils.controlBox(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={true}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3}>
              <CellField
                id={`vbox_${side}`}
                name={`vbox`}
                label={t("10144")}
                value={vbox}
                check={(v) => JobControlUtils.controlBox(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={true}
                requiredFields={requiredFields}
              />
            </Grid>

            {
              /*  RIGA 6  
                  angolo pantoscopico - altezza montaggio - dima orizzontale - dima verticale
              */
            }
            <Grid item xs={3}>
              <CellField
                id={`panto_${side}`}
                name={`panto`}
                label={t("10418")}
                value={customProductPersonalized ? personalized ? panto : "" : panto}
                check={(v) => JobControlUtils.controlPanto(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={customProductPersonalized === false ? !editMode : (!editMode || !personalized)}
                requiredFields={requiredFields}
              />
            </Grid>

            <Grid item xs={3}>
              <CellField
                id={`ztilt_${side}`}
                name={`ztilt`}
                label={t("10402")}
                value={customProductPersonalized ? personalized ? ztilt : "" : ztilt}
                check={(v) => JobControlUtils.controlZtilt(v, t)}
                onChange={onChange}
                format={(v) => v}
                onblur={onblur}
                disabled={customProductPersonalized === false ? !editMode : (!editMode || !personalized)}
                requiredFields={requiredFields}
              />
            </Grid>

            <Grid item xs={3}>
              <CellField
                id={`bvd_${side}`}
                name={`bvd`}
                label={t("10419")}
                value={customProductPersonalized ? personalized ? bvd : "" : bvd}
                check={(v) => JobControlUtils.controlCornealApex(v, t)}
                onChange={onChange}
                format={JobsUtils.formatGeneric}
                onblur={onblur}
                disabled={customProductPersonalized === false ? !editMode : (!editMode || !personalized)}
                requiredFields={requiredFields}
              />
            </Grid>
            <Grid item xs={3} />

          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}

// Componente per i dati della lente destra/sinistra
function EdgingDataGrid({
  edgingDetails,
  editMode,
  onblur,
  onChangeEdgingData,
  onChangeNotes,
  notesDetails,
  requiredFields,
  creation,
}) {
  const { etyp, polish, dbl, bevp, bevm, ftyp, offset, gwidth, gdepth, fpinb, pinb } = edgingDetails
  const { precal } = notesDetails
  const { t } = useContext(RootContext)
  let bevr = ""
  let bevmEnable = true
  let pinbEnable = false
  let bevmVideo
  let bevpVideo
  let gwidthVideo
  let gdepthVideo
  let fpinbVideo
  let pinbVideo

  switch (etyp) {
    case "":
    case "1":
      pinbEnable = false
      break
    case "2":
    case "3":
      pinbEnable = true
      break
    default:
      break
  }

  // Changes (mm) or (%)
  switch (bevp) {
    case "":
    case "1":
      bevr = " (mm)"
      break
    case "2":
      bevr = " (%)"
      break
    case "4":
      bevr = " (%)"
      bevmEnable = false
      break
    case "5":
      bevr = " (mm)"
      break
    case "0":
    case "7":
      bevmEnable = false
      break
    default:
      break
  }
  // BEVM -> Extracts only first value
  if (bevm.indexOf(";") > 0) {
    bevmVideo = bevm.substr(0, bevm.indexOf(";"))
  }

  // BEVP -> Extracts only first value
  if (bevp.indexOf(";") > 0) {
    bevpVideo = bevp.substr(0, bevp.indexOf(";"))
  }

  //GWIDTH -> Extracts only first value
  if (gwidth.indexOf(";") > 0) {
    gwidthVideo = gwidth.substr(0, gwidth.indexOf(";"))
  }

  //GDEPTH -> Extracts only first value
  if (gdepth.indexOf(";") > 0) {
    gdepthVideo = gdepth.substr(0, gdepth.indexOf(";"))
  }

  //FPINB -> Extracts only first value
  if (fpinb.indexOf(";") > 0) {
    fpinbVideo = fpinb.substr(0, fpinb.indexOf(";"))
  }

  //GDEPTH -> Extracts only first value
  if (pinb.indexOf(";") > 0) {
    pinbVideo = fpinb.substr(0, pinb.indexOf(";"))
  }

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      {
        /*  RIGA 1  
            tipo montaggio - posizione bisello - valore posizione - larghezza canalino
        */
      }
      <Grid item xs={3}>
        <SelectField
          id={`etyp`}
          name={`etyp`}
          label={t("10035")}
          helpText={(etyp === "3" ? t("10247") : t("10124"))}
          value={etyp}
          optionList={JobsUtils.edgingTypeValues()}
          onChange={onChangeEdgingData}
          disabled={!editMode}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          id={"bevp"}
          name={"bevp"}
          label={t("10007") + bevr}
          helpText={t("10115")}
          value={bevpVideo ? bevpVideo : bevp}
          optionList={JobsUtils.positionTypeValues()}
          onChange={onChangeEdgingData}
          disabled={!editMode}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`bevm`}
          name={"bevm"}
          label={t("10006") + bevr}
          value={bevmVideo ? bevmVideo : bevm}
          check={(e) => JobControlUtils.controlBevm(e, bevr.trim(), t)}
          onChange={onChangeEdgingData}
          disabled={!editMode || !bevmEnable}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          id={"polish"}
          name={"polish"}
          label={t("10102")}
          helpText={t("10116")}
          value={polish}
          optionList={JobsUtils.polishValues()}
          onChange={onChangeEdgingData}
          disabled={!editMode}
          requiredFields={requiredFields}
        />
      </Grid>

      {
        /*  RIGA 2  
            larghezza canalino - profondità canalino - smusso basso - smusso alto
        */
      }
      <Grid item xs={3}>
        <CellField
          id={`gwidth`}
          name={"gwidth"}
          label={t("10305") + " (mm)"}
          value={etyp !== "3" ? "" : (gwidthVideo ? gwidthVideo : gwidth)}
          check={(e) => JobControlUtils.controlGwidth(e, t)}
          onChange={onChangeEdgingData}
          disabled={!editMode || etyp !== "3"}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`gdepth`}
          name={"gdepth"}
          label={t("10306") + " (mm)"}
          value={etyp !== "3" ? "" : (gdepthVideo ? gdepthVideo : gdepth)}
          check={(e) => JobControlUtils.controlGwidth(e, t)}
          onChange={onChangeEdgingData}
          disabled={!editMode || etyp !== "3"}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`fpinb`}
          name={"fpinb"}
          label={t("10308") + " (mm)"}
          value={(fpinbVideo ? fpinbVideo : fpinb)}
          check={(e) => JobControlUtils.controlPinb(e, t)}
          onChange={onChangeEdgingData}
          disabled={!editMode}
          requiredFields={requiredFields}
          helperText={t("10391")}
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`pinb`}
          name={"pinb"}
          label={t("10307") + " (mm)"}
          value={pinbEnable ? (pinbVideo ? pinbVideo : pinb) : ""}
          check={(e) => JobControlUtils.controlPinb(e, t)}
          onChange={onChangeEdgingData}
          disabled={!editMode || !pinbEnable}
          requiredFields={requiredFields}
          helperText={t("10390")}
        />
      </Grid>
      {
        /*  RIGA 3  
            finitura bordo - ponte - offset
        */
      }
      <Grid item xs={3}>
        <SelectField
          id={"ftyp"}
          name={"ftyp"}
          label={t("10040")}
          helpText={t("10125")}
          value={ftyp}
          optionList={JobsUtils.frameTypeValues()}
          onChange={onChangeEdgingData}
          disabled={!editMode}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`dbl`}
          name={`dbl`}
          label={t("10025")}
          value={dbl}
          check={(v) => JobControlUtils.controlDbl(v, t)}
          onChange={onChangeEdgingData}
          format={JobsUtils.formatGeneric}
          onblur={onblur}
          disabled={!editMode}
          requiredFields={requiredFields}
        // type="number"
        />
      </Grid>
      <Grid item xs={3}>
        <CellField
          id={`offset`}
          name={`offset`}
          label={t("10089")}
          value={offset}
          check={(v) => JobControlUtils.controlOffset(v, t)}
          onChange={onChangeEdgingData}
          format={JobsUtils.formatSphereCylinder}
          onblur={onblur}
          disabled={!creation}
          requiredFields={requiredFields}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          id={"precal"}
          name={"precal"}
          label={t("precal")}
          //helpText={t("10125")}
          value={precal}
          optionList={JobsUtils.getPrecalValue()}
          onChange={onChangeNotes}
          disabled
          requiredFields={requiredFields}
        />
      </Grid>
    </Grid>
  )
}
// Componente per i dati della lente destra/sinistra
function NotesDataGrid({ edgingDetails, editMode, onChange, status }) {
  const { t, isLabUser, labScopes } = useContext(RootContext)
  if (edgingDetails) {
    const {
      opticianNote,
      labNote,
      opticianCustomer,
      lensOrderReference,
      lensOrderLabReference,
    } = edgingDetails
    let route = undefined
    let lensOrderLabReferenceView = lensOrderLabReference
    if (labScopes && lensOrderLabReference.length > 0) {
      let scopeSelected = labScopes.filter((elem) => {
        return elem.active === true && elem.scopeId === 8
      })

      if (scopeSelected.length > 0) {
        let labSettings = scopeSelected[0].labSettings
        if (labSettings) {
          route = labSettings.routings
        }
        let appSupplier = route.filter((option) => { return option.supplierCode === lensOrderLabReference })
        lensOrderLabReferenceView = appSupplier.length > 0 ? appSupplier[0].supplier : lensOrderLabReference
      }
    }
    return (
      <div>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <p>
              <u>{t("10038").toUpperCase()}</u>
            </p>
          </Grid>
          <Grid item xs={isLabUser() ? 4 : 6}>
            <NotesField
              id="opticianNote"
              label={t("10094")}
              value={opticianNote}
              onChange={onChange}
              disabled={!editMode}
            />
          </Grid>
          <Grid item xs={isLabUser() ? 4 : 6}>
            <NotesField
              id="opticianCustomer"
              label={t("10093")}
              value={opticianCustomer}
              onChange={onChange}
              disabled={!editMode}
            />
          </Grid>
          {isLabUser() ? (
            <Grid item xs={4}>
              <NotesField
                id="labNote"
                label={t("10061")}
                value={labNote}
                onChange={onChange}
                disabled={!editMode}
              />
            </Grid>
          ) : null}
          {isLabUser() ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12}>
                <p>
                  <u>{t("10066").toUpperCase()}</u>
                </p>
              </Grid>
              <Grid item xs={4}>
                <NotesField
                  id="lensOrderLabReference"
                  label={t("10060")}
                  value={lensOrderLabReferenceView}
                  onChange={onChange}
                  disabled={!editMode}
                />
              </Grid>
              <Grid item xs={4}>
                <NotesField
                  id="lensOrderReference"
                  label={t("10096")}
                  value={lensOrderReference}
                  onChange={onChange}
                  disabled={!editMode}
                  status={status}
                />
              </Grid>
            </Grid>
          ) : null}{" "}
        </Grid>
      </div>
    )
  } else {
    return null
  }
}

export {
  LensDataGrid,
  EdgingDataGrid,
  NotesDataGrid,
  HeadDataGrid,
  DeviceDataGrid,
}
