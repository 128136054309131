import React, { useCallback, useState, useContext, useEffect } from "react"
import { RootContext } from "../../RootContext"

// Material ui component
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"
import TextField from '@material-ui/core/TextField';

// Css
import { useShapeStylesNumber } from "styles/shape/shapeCss"

//Pegaso component
import ShapeUtils from "components/Utils/ShapeUtils"
import OmaUtils from "components/Utils/OmaUtils"

export default function ModifyShape(props) {
  const {
    t,
    apiUrlPegasoTools,
    access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    setAccess_token_pegaso_tools
  } = useContext(RootContext)

  const { calibration, edgingData, handleUpdateShape, scaleX, scaleY, setOpenShapeModify, setWrongFormatFile, shapeJson, shapeModifyJson, setShapeModifyJson } = props
  const classes = useShapeStylesNumber()

  // vecchi campi
  const [upVal, setUpVal] = useState(0)
  const [downVal, setDownVal] = useState(0)
  const [rightVal, setRightVal] = useState(0)
  const [leftVal, setLeftVal] = useState(0)

  // nuovi campi
  const [currentHbox, setCurrentHbox] = useState(shapeJson.right.hbox)
  const [currentVbox, setCurrentVbox] = useState(shapeJson.right.vbox)

  const [keepProportions, setKeepProportions] = useState(false)
  const [typeEditDrill, setTypeEditDrill] = useState(1)
  const maxVal = 85
  const minVal = 15
  const [mustChange, setMustChange] = useState(false)
  const cloneShapeJson = JSON.parse(JSON.stringify(shapeJson));
  const hasDrills = shapeJson.drillingPoints ? (shapeJson.drillingPoints.length > 0) : false

  // Quadranti
  const newCalibration = { dpiX: 4 * calibration.dpiX, dpiY: 4 * calibration.dpiY, }
  const multDelta = 100
  const numPoints = shapeJson.right.points.length
  const quadrante1 = {
    start: 0,
    end: numPoints / 4
  }
  const quadrante2 = {
    start: numPoints / 4,
    end: numPoints * 2 / 4
  }
  const quadrante3 = {
    start: numPoints * 2 / 4,
    end: numPoints * 3 / 4
  }
  const quadrante4 = {
    start: numPoints * 3 / 4,
    end: numPoints
  }
  const editDrillOption = [
    { value: 0, label: "" }, { value: 1, label: t("10356") }, { value: 2, label: t("10357") },
  ]
  /*************************************
  *
  * Handler per modificare i valori
  *
  *************************************/
  //Salva la forma modificata
  const handleSaveShapeModify = () => {
    // Converto i punti in raggi
    let radiusR = ShapeUtils.pointsToRadius(shapeModifyJson.right.points, scaleX, scaleY, shapeModifyJson.right.points.length, calibration)
    let radiusL = ShapeUtils.pointsToRadius(shapeModifyJson.left.points, scaleX, scaleY, shapeModifyJson.left.points.length, calibration)
    // Modifico le dime se ci sono
    let newEdgingData = edgingData + "\r\n"
    let newHBOX = currentHbox + ";" + currentHbox
    newEdgingData = OmaUtils.ReplaceSingleValueOMA(newEdgingData, "HBOX", newHBOX)
    let newVBOX = currentVbox + ";" + currentVbox
    newEdgingData = OmaUtils.ReplaceSingleValueOMA(newEdgingData, "VBOX", newVBOX)
    while (newEdgingData.indexOf("DRILLE") >= 0) {
      newEdgingData = OmaUtils.RemoveOmaLabel(newEdgingData.split("\r\n"), "DRILLE").join("\r\n")
    }
    while (newEdgingData.indexOf("DRILL") >= 0) {
      newEdgingData = OmaUtils.RemoveOmaLabel(newEdgingData.split("\r\n"), "DRILL").join("\r\n")
    }
    let edgingDataSplit = newEdgingData.split("\r\n")
    let drillValue = shapeModifyJson.drillingPoints
    drillValue.map((drill) => { return edgingDataSplit.push("DRILL=" + OmaUtils.CreateOmaDrillRecord(drill) + "\r\n") })
    newEdgingData = edgingDataSplit.join("\r\n")
    // Crea nuovo file OMA)
    let createOmaShapeFromData = OmaUtils.CreateOmaShapeFromData(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, radiusR.join(';'), radiusL.join(';'), newEdgingData)
    createOmaShapeFromData.then(function (result) {
      var omaShape = result
      // Salva forma
      var tracerDataConverted = OmaUtils.ConvertDataToOma(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, omaShape, 3)
      tracerDataConverted.then(function (result) {
        var dataToOma = result
        if (dataToOma.length === 2) {
          if (
            dataToOma[0].trim() !== "" ||
            dataToOma[1].trim() !== ""
          ) {
            setWrongFormatFile(false)
            const standardShape = dataToOma[0].trim()
            const edgingDataConverted = dataToOma[1]
            handleUpdateShape(omaShape, standardShape, edgingDataConverted, false)
          } else {
            setWrongFormatFile(true)
          }
        } else {
          setWrongFormatFile(true)
        }
        setShapeModifyJson()
        setOpenShapeModify(false)
      })
    })

  }

  //Handler per la chiusura della card del modifica forma
  function handleCloseShapeModify() {
    setShapeModifyJson()
    setOpenShapeModify(false)
  }

  // Individua se punto è Y o X nell'array di punti
  function pointIsY(curr) {
    return (curr % 2 !== 0)
  }

  // Individua l'estremità più lontana e restituisce la posizione del punto
  const searchPosMax = useCallback((oldPoints, side, direction) => {
    let posMaxPoint = (direction === "U" || direction === "D") ? 1 : 0
    let maxPoint = 0
    let currentPoint = 0

    oldPoints.map((point) => {
      if (side === "R") {
        /* 
            LATO DESTRO 
        */
        switch (direction) {
          case "U": // LATO DESTRO  - UP
            if (currentPoint >= quadrante1.start && currentPoint <= quadrante2.end) {
              // QUADRANTE 1-2
              if (pointIsY(currentPoint)) {
                if (point < maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "D": // LATO DESTRO  - DOWN
            if (currentPoint >= quadrante3.start && currentPoint <= quadrante4.end) {
              // QUADRANTE 3-4
              if (pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "L": // LATO DESTRO  - LEFT
            if (currentPoint >= quadrante2.start && currentPoint <= quadrante3.end) {
              // QUADRANTE 2-3
              if (!pointIsY(currentPoint)) {
                if (point < maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "R": // LATO DESTRO  - RIGHT
            if (currentPoint >= quadrante1.start && currentPoint <= quadrante1.end) {
              // QUADRANTE 1
              if (!pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            } else if (currentPoint >= quadrante4.start && currentPoint <= quadrante4.end) {
              // QUADRANTE 4
              if (!pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          default:
            break;
        }
      } else {
        /* 
            LATO SINISTRO 
        */
        switch (direction) {
          case "U": // LATO DESTRO  - UP
            if (currentPoint >= quadrante1.start && currentPoint <= quadrante2.end) {
              // QUADRANTE 1-2
              if (pointIsY(currentPoint)) {
                if (point < maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "D": // LATO DESTRO  - DOWN
            if (currentPoint >= quadrante3.start && currentPoint <= quadrante4.end) {
              // QUADRANTE 3-4
              if (pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "L": // LATO DESTRO  - LEFT
            if (currentPoint >= quadrante1.start && currentPoint <= quadrante1.end) {
              // QUADRANTE 1
              if (!pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            } else if (currentPoint >= quadrante4.start && currentPoint <= quadrante4.end) {
              // QUADRANTE 4
              if (!pointIsY(currentPoint)) {
                if (point > maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          case "R": // LATO DESTRO  - RIGHT
            if (currentPoint >= quadrante2.start && currentPoint <= quadrante3.end) {
              // QUADRANTE 2-3
              if (!pointIsY(currentPoint)) {
                if (point < maxPoint) {
                  maxPoint = point
                  posMaxPoint = currentPoint
                }
              }
            }
            break;
          default:
            break;
        }
      }
      currentPoint += 1
      return point
    })

    return posMaxPoint
  }, [quadrante1, quadrante2, quadrante3, quadrante4])

  const changePoints_UP = useCallback((point, posPoint, posMax, delta, pointMax) => {
    let newPoint = point
    let deltaCalibrated
    if (posPoint === posMax) {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiY) * scaleY)
      newPoint = newPoint - deltaCalibrated
    } else {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiY) * scaleY)
      newPoint = newPoint - (Math.abs(newPoint) * deltaCalibrated) / Math.abs(pointMax)
    }
    return newPoint
  }, [newCalibration, scaleY])

  const changePoints_DOWN = useCallback((point, posPoint, posMax, delta, pointMax) => {
    let newPoint = point
    let deltaCalibrated
    if (posPoint === posMax) {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiY) * scaleY)
      newPoint = newPoint + deltaCalibrated
    } else {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiY) * scaleY)
      newPoint = newPoint + (Math.abs(newPoint) * deltaCalibrated) / Math.abs(pointMax)
    }
    return newPoint
  }, [newCalibration, scaleY])

  const changePoints_LEFT = useCallback((point, posPoint, posMax, delta, pointMax) => {
    let newPoint = point
    let deltaCalibrated
    if (posPoint === posMax) {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiX) * scaleX)
      newPoint = newPoint - deltaCalibrated
    } else {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiX) * scaleX)
      newPoint = newPoint - (Math.abs(newPoint) * deltaCalibrated) / Math.abs(pointMax)
    }
    return newPoint
  }, [newCalibration, scaleX])

  const changePoints_RIGHT = useCallback((point, posPoint, posMax, delta, pointMax) => {
    let newPoint = point
    let deltaCalibrated
    if (posPoint === posMax) {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiX) * scaleX)
      newPoint = newPoint + deltaCalibrated
    } else {
      deltaCalibrated = parseFloat((multDelta * delta / newCalibration.dpiX) * scaleX)
      newPoint = newPoint + (Math.abs(newPoint) * deltaCalibrated) / Math.abs(pointMax)
    }
    return newPoint
  }, [newCalibration, scaleX])

  //Modifica i raggi della forma dei valori selezionati dall'utente
  const modifyShapeJsonPointsSide = useCallback((oldPoints, side, direction, delta) => {
    let newPoints
    let pointMax
    let posPoint = 0
    let posMax = (direction === "U" || direction === "D") ? 1 : 0

    if (delta !== 0) {
      // CALCOLA UNITA DI MISURA    
      posMax = searchPosMax(oldPoints, side, direction)
      pointMax = oldPoints[posMax]

      // Crea punti nuovi
      newPoints = oldPoints.map(point => {
        let newPoint = parseFloat(point)

        if (side === "R") {
          /* 
              LATO DESTRO 
          */
          switch (direction) {
            case "U": // LATO DESTRO  - UP (PUNTI Y)
              if (posPoint >= quadrante1.start && posPoint <= quadrante2.end) {
                if (pointIsY(posPoint)) {
                  newPoint = changePoints_UP(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "D": // LATO DESTRO  - DOWN (PUNTI Y)
              if (posPoint >= quadrante3.start && posPoint <= quadrante4.end) {
                if (pointIsY(posPoint)) {
                  newPoint = changePoints_DOWN(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "L": // LATO DESTRO  - LEFT (PUNTI X)
              if ((posPoint >= quadrante2.start && posPoint <= quadrante3.end)) {
                if (!pointIsY(posPoint)) {
                  newPoint = changePoints_LEFT(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "R": // LATO DESTRO  - RIGHT (PUNTI X)
              if (!pointIsY(posPoint)) {
                if ((posPoint >= quadrante1.start && posPoint <= quadrante1.end) || (posPoint >= quadrante4.start && posPoint <= quadrante4.end)) {
                  newPoint = changePoints_RIGHT(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            default:
              newPoint = point
              break;
          }

        } else {
          /* 
              LATO SINISTRO 
          */
          switch (direction) {
            case "U": // LATO SINISTRO  - UP (PUNTI Y)
              if (posPoint >= quadrante1.start && posPoint <= quadrante2.end) {
                if (pointIsY(posPoint)) {
                  newPoint = changePoints_UP(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "D": // LATO SINISTRO  - DOWN (PUNTI Y)
              if (posPoint >= quadrante3.start && posPoint <= quadrante4.end) {
                if (pointIsY(posPoint)) {
                  newPoint = changePoints_DOWN(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "L": // LATO SINISTRO  - LEFT (PUNTI X)
              if ((posPoint >= quadrante1.start && posPoint <= quadrante1.end) || (posPoint >= quadrante4.start && posPoint <= quadrante4.end)) {
                if (!pointIsY(posPoint)) {
                  newPoint = changePoints_RIGHT(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            case "R": // LATO SINISTRO  - RIGHT (PUNTI X)
              if ((posPoint >= quadrante2.start && posPoint <= quadrante3.end)) {
                if (!pointIsY(posPoint)) {
                  newPoint = changePoints_LEFT(point, posPoint, posMax, delta, pointMax)
                }
              }
              break;
            default:
              newPoint = point
              break;
          }
        }
        posPoint++
        return newPoint
      }) // MAP

      // Applicare beizer ad array
      //new Bezier(newPoints)

    } else {
      newPoints = oldPoints
    }
    return newPoints
  }, [changePoints_DOWN, changePoints_UP, changePoints_RIGHT, changePoints_LEFT,
    quadrante1, quadrante2, quadrante3, quadrante4, searchPosMax,
  ])

  //Modifica la posizione dei punti della forma dei valori selezionati dall'utente
  const modifyShapeJsonDrillPosition = useCallback((drillingPoints, direction, delta) => {
    let newDrillPosition
    if (delta !== 0) {
      newDrillPosition = drillingPoints.map(point => {
        let newPoint = point
        if (typeEditDrill === 1) {
          switch (direction) {
            case "U": //UP (PUNTI Y)
              if (point.yStart > 0) {
                newPoint.yStart = point.yStart + delta
                newPoint.yEnd = point.yEnd ? point.yEnd + delta : null
              }
              break;
            case "D": //DOWN (PUNTI Y)
              if (point.yStart < 0) {
                newPoint.yStart = point.yStart - delta
                newPoint.yEnd = point.yEnd ? point.yEnd - delta : null
              }
              break;
            case "L": //LEFT (PUNTI X)
              if (point.xStart < 0) {
                newPoint.xStart = point.xStart - delta
                newPoint.xEnd = point.xEnd ? point.xEnd - delta : null
              }
              break;
            case "R": //RIGHT (PUNTI X)
              if (point.xStart > 0) {
                newPoint.xStart = point.xStart + delta
                newPoint.xEnd = point.xEnd ? point.xEnd + delta : null
              }
              break;
            default:
              break;
          }
        }
        else if (typeEditDrill === 2) {
          switch (direction) {
            case "L": //LEFT (PUNTI X)
              if (point.xStart < 0) {
                newPoint.xStart = point.xStart - delta
                newPoint.xEnd = point.xEnd ? point.xEnd - delta : null
              }
              break;
            case "R": //RIGHT (PUNTI X)
              if (point.xStart > 0) {
                newPoint.xStart = point.xStart + delta
                newPoint.xEnd = point.xEnd ? point.xEnd + delta : null
              }
              break;
            default:
              break;
          }
        }
        return newPoint
      }) // MAP

      // Applicare beizer ad array
      //new Bezier(newPoints)

    } else {
      newDrillPosition = drillingPoints
    }
    return newDrillPosition
  }, [typeEditDrill])

  //Modifica le quattro dimensioni della forma (su,giu,destra,sinistra)
  const modifyShapeJsonPoints = useCallback((up, right, left, down) => {
    let oldRightPoints = shapeJson.right.points
    let oldLeftPoints = shapeJson.left.points
    let oldDrillingPoint = cloneShapeJson.drillingPoints

    // LENTE DESTRA - Creo nuovi punti
    let newRightPoints = oldRightPoints
    newRightPoints = modifyShapeJsonPointsSide(newRightPoints, "R", "U", up)
    newRightPoints = modifyShapeJsonPointsSide(newRightPoints, "R", "L", left)
    newRightPoints = modifyShapeJsonPointsSide(newRightPoints, "R", "R", right)
    newRightPoints = modifyShapeJsonPointsSide(newRightPoints, "R", "D", down)

    // LENTE SINISTRA - Creo nuovi punti
    let newLeftPoints = oldLeftPoints
    newLeftPoints = modifyShapeJsonPointsSide(newLeftPoints, "L", "U", up)
    newLeftPoints = modifyShapeJsonPointsSide(newLeftPoints, "L", "L", left)
    newLeftPoints = modifyShapeJsonPointsSide(newLeftPoints, "L", "R", right)
    newLeftPoints = modifyShapeJsonPointsSide(newLeftPoints, "L", "D", down)

    // FORI - Creo nuovi punti
    let newDrillingPoint = oldDrillingPoint
    newDrillingPoint = modifyShapeJsonDrillPosition(newDrillingPoint, "U", up)
    newDrillingPoint = modifyShapeJsonDrillPosition(newDrillingPoint, "L", left)
    newDrillingPoint = modifyShapeJsonDrillPosition(newDrillingPoint, "R", right)
    newDrillingPoint = modifyShapeJsonDrillPosition(newDrillingPoint, "D", down)

    // Aggiorno forma ROSSA
    setShapeModifyJson({
      ...shapeModifyJson,
      right: {
        ...shapeModifyJson.right,
        points: newRightPoints
      },
      left: {
        ...shapeModifyJson.left,
        points: newLeftPoints
      },
      drillingPoints: newDrillingPoint
    })

  }, [cloneShapeJson, modifyShapeJsonPointsSide, modifyShapeJsonDrillPosition, shapeJson, setShapeModifyJson, shapeModifyJson])

  // Fix of value
  function fixChangeValue(inVal) {
    let outVal
    if (inVal) {
      outVal = parseFloat(parseFloat(inVal).toFixed(2))
      if (outVal > maxVal) {
        outVal = maxVal
      } else if (outVal < minVal) {
        outVal = minVal
      }
    } else {
      outVal = 0
    }
    return outVal
  }

  //Modifica il tipo di modifica dei fori
  const handleChangeTypeEditDrill = (event) => {
    let valueInserted = (event.target.value)
    setTypeEditDrill(valueInserted)
  }

  //Modifica la forma Hbox
  const onChangeHbox = (event) => {
    setCurrentHbox(event.target.value)
  }

  const onBlurHbox = (event) => {
    let newHbox = fixChangeValue(event.target.value)
    let difference = (newHbox - shapeJson.right.hbox) / 2
    let newDifference = rightVal - difference

    setRightVal(difference)
    setLeftVal(difference)
    setCurrentHbox(newHbox)

    if (keepProportions) {
      let newVbox = fixChangeValue(currentVbox - newDifference * 2)
      setUpVal(upVal - newDifference)
      setDownVal(downVal - newDifference)
      setCurrentVbox(newVbox)
    }
    setMustChange(true)
  }

  //Modifica la forma Vbox
  const onChangeVbox = (event) => {
    setCurrentVbox(event.target.value)
  }

  const onBlurVbox = (event) => {
    let newVbox = fixChangeValue(event.target.value)
    let difference = (newVbox - shapeJson.right.vbox) / 2
    let newDifference = upVal - difference

    setUpVal(difference)
    setDownVal(difference)
    setCurrentVbox(newVbox)

    if (keepProportions) {
      let newHbox = fixChangeValue(currentHbox - newDifference * 2)
      setRightVal(rightVal - newDifference)
      setLeftVal(leftVal - newDifference)
      setCurrentHbox(newHbox)
    }
    setMustChange(true)
  }

  //Cambia field (checkbox)
  const handleChangeCheckedKeepProportion = () => (event) => {
    setKeepProportions(event.target.checked)
  }

  /*************************************
  *
  * UseEffect
  *
  *************************************/

  // Solo se non è valorizzato
  useEffect(() => {
    if (!shapeModifyJson) {
      setShapeModifyJson(shapeJson)
    }
  }, [shapeJson, shapeModifyJson, setShapeModifyJson])

  // Aggiorna disegno forma ROSSA se modifichi valori
  useEffect(() => {
    if (shapeModifyJson) {
      if (mustChange) {
        modifyShapeJsonPoints(upVal, rightVal, leftVal, downVal)
        setMustChange(false)
      }
    }
  }, [downVal, leftVal, modifyShapeJsonPoints, mustChange, rightVal, setMustChange, shapeModifyJson, upVal])

  return (
    <div>
      {/* X CHIUSURA IN ALTO */}
      <Box display="flex" flexDirection="row-reverse" p={1}>
        <Box p={1}>
          <IconButton
            aria-label={t("10238")}
            variant="outlined"
            onClick={handleCloseShapeModify}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item xs={12}>
          <p align="center">
            <u>{t("10347").toUpperCase()}</u>
          </p>
        </Grid>

        <Grid container item xs={12}>
          {/* MANTIENI PROPORZIONI */}
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={keepProportions}
                  id="keepPorportion"
                  onChange={handleChangeCheckedKeepProportion()}
                  name="keepPorportion"
                />
              }
              label={t("10436")}
            />
          </Grid>

          {/* SPOSTAMENTO HBOX */}
          <Grid item xs={2}>
            <TextField
              className={classes.textField}
              id="mod-shape-hbox"
              InputProps={{ inputProps: { min: minVal, max: maxVal, step: 0.5 } }}
              InputLabelProps={{ shrink: true }}
              label={t("10043")}
              onMouseUp={onBlurHbox}
              onChange={onChangeHbox}
              onBlur={onBlurHbox}
              type="number"
              value={currentHbox}
              variant="outlined"
            />
          </Grid>

          {/* SPOSTAMENTO VBOX */}
          <Grid item xs={2}>
            <TextField
              className={classes.textField}
              id="mod-shape-vbox"
              InputProps={{ inputProps: { min: minVal, max: maxVal, step: 0.5 } }}
              InputLabelProps={{ shrink: true }}
              label={t("10144")}
              onMouseUp={onBlurVbox}
              onChange={onChangeVbox}
              onBlur={onBlurVbox}
              type="number"
              value={currentVbox}
              variant="outlined"
            />
          </Grid>

          {/* SPOSTAMENTO FORI */}
          <Grid item xs={2}>
            {
              hasDrills ?
                < TextField
                  select
                  id={"typeEditDrill"}
                  label={t("10354")}
                  className={classes.textField}
                  value={typeEditDrill}
                  margin="normal"
                  helperText={t("10355")}
                  onChange={handleChangeTypeEditDrill}
                >
                  {editDrillOption.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                :
                null
            }
          </Grid>

          {/* BOTTONE SALVA */}
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSaveShapeModify}
            >
              {t("10348")}
            </Button>
          </Grid>

        </Grid>
      </Grid>
    </div >
  )
}