import React, { useEffect, useState, useContext, useCallback, Fragment } from "react"
import { RootContext } from "../../RootContext"

// Core components
import AddIcon from "@material-ui/icons/Add"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import Fab from "@material-ui/core/Fab"
import ErrorUtils from "components/Tools/ErrorUtils"
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete"

import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import RefreshIcon from "@material-ui/icons/Refresh"
import Tooltip from "@material-ui/core/Tooltip"
import { CircularIndeterminate } from "../Loading"
import { fetchWithToken } from "components/Fetch/api-fetch"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from '@material-ui/core/Paper';
// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import SingleSideShape from "views/LoadedShape/SingleSideShape"
import LoadedShape from "views/LoadedShape/LoadedShape"
import { makeStyles } from '@material-ui/core/styles';
//Pegaso components

export default function LoadedShapes() {
  const {
    companyId,
    t,
    apiUrl,
    access_token,
    refresh_token,
    setAccess_token
  } = useContext(RootContext)

  const classesJob = useJobsStyles()
  const [creation, setCreation] = useState(false)
  const [dialogMsg, setDialogMsg] = useState("")
  const [isFetchingShapes, setIsFetchingShapes] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [openDeleteShape, setOpenDeleteShape] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [openShape, setOpenShape] = useState(false)
  const [shapes, setShapes] = useState([])
  const [shapeIdToDelete, setShapeIdToDelete] = useState(undefined)
  const [shapeData, setShapeData] = useState("")

  /**************************************************************************
   *                    FUNZIONI
   *****************************************************************************/

  const useStyles = makeStyles(() => ({
    paper: { borderRadius: 20, borderColor: "#000000", padding: 10 }
  }));

  const classesPaper = useStyles();

  function ShowShapes() {
    return (
      <Grid
        container
        bgcolor="background.paper"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {shapes.map((item) => (
          <Grid item xs={2}
            key={item.shapeId}>
            <Paper className={classesPaper.paper}
              variant="outlined"
            >
              {item.shapeId + " - " + item.description}
              <Tooltip title={t("10032")}>
                <IconButton aria-label={t("10154")} variant="outlined" onClick={() => { handleClickOpenEdit(item.shapeId) }}>
                  {<EditIcon />}
                </IconButton>
              </Tooltip >
              <Tooltip title={t("10158")}>
                <IconButton aria-label={t("10154")} variant="outlined" onClick={() => { handleClickOpenDelete(item.shapeId) }}>
                  {<DeleteIcon />}
                </IconButton>
              </Tooltip >
              <SingleSideShape
                creation={false}
                edgingData={""}
                editMode={false}
                frameMode={true}
                isGlasant={false}
                jobId={item.shapeId}
                jobSoftware={""}
                offset={0}
                scaleX={4}
                scaleY={4}
                shape={item.shape.standard}
              />
            </Paper>
          </Grid>

        ))}
      </Grid>
    )
  }



  /**************************************************************************
   *                    HANDLER
   *****************************************************************************/
  // Click Open New Frame
  function handleClickOpenNew() {
    setOpenShape(true)
    setCreation(true)
  }
  // Click Open Edit Frame
  function handleClickOpenEdit(shapeId) {
    setOpenShape(true)
    setCreation(false)
    var newShapeData = shapes.filter(option => option.shapeId === shapeId)[0]
    setShapeData(newShapeData)
  }

  // Click Open Delete Frame
  function handleClickOpenDelete(shapeId) {
    setOpenDeleteShape(true)
    setShapeIdToDelete(shapeId)
  }

  // Click Close Delete dialog
  function handleClickCloseDelete() {
    setOpenDeleteShape(false)
  }

  // Click Close Frame
  function handleClickClose() {
    setShapeData("")
    setOpenShape(false)
    setCreation(false)
  }

  const handleClose = () => {
    setOpenDeleteShape(false)
    setOpenDialog(false)
    setIsSaving(false)
    handleClickRefresh()
  }

  // Click refresh
  function handleClickRefresh() {
    setIsFetchingShapes(true)
  }

  /**************************************************************************
   *                    FETCH
   *****************************************************************************/

  //Fetch delle forme
  const fetchShapes = useCallback(() => {
    if (isFetchingShapes === true) {
      fetchWithToken(`${apiUrl}/companies/${companyId}/listLoadedShape`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { shapes } = body
          setShapes(shapes)
          setIsFetchingShapes(false)
        })
        .catch(err => {
          setIsFetchingShapes(false)
          ErrorUtils.errorLog("RequiredField Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingShapes, refresh_token, setAccess_token,])

  //Fetch cancella forma
  const fetchDeleteShape = () => {
    setIsSaving(true)
    const url = `${apiUrl}/companies/${companyId}/loadedShape/${shapeIdToDelete}`
    fetchWithToken(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token,
    })
      .then((response) => {
        return response.body
      })
      .then((data) => {
        setOpenDialog(true)
        setDialogMsg(t("Forma eliminata corettamente"))
      })
      .catch((err) => {
        ErrorUtils.errorLog(t("1"), err)
        setDialogMsg(t("1"))
        setOpenDialog(true)
        setIsSaving(false)
      })
  }

  /**
    * 
    *USE EFFECT
    */
  useEffect(() => {
    if (isFetchingShapes) {
      fetchShapes()
    }
  }, [isFetchingShapes, fetchShapes])


  return (
    <div>

      <Card>
        <CardHeader color="primary">
          <h4 className={classesJob.cardTitleWhite}>{t("10431").toUpperCase()}</h4>
        </CardHeader>

        <CardBody>
          {isFetchingShapes ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start">
              <CircularIndeterminate />
            </Grid>
          ) : (
            <div>
              <Grid
                container
                direction="row-reverse"
                justifyContent="space-between"
                spacing={1}
                alignItems="center">
                <Grid item xs={1}>
                  {" "}
                  <Tooltip title={t("10433")}>
                    <Fab
                      color="primary"
                      onClick={handleClickOpenNew}
                      aria-label="add"
                      align="right"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Grid>

                {/* TASTO REFRESH JOBS*/}
                <Grid item xs={1}>
                  <Tooltip title={t("10432")}>
                    <IconButton
                      onClick={handleClickRefresh}
                      aria-label="refresh"
                      align="left"
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={10}></Grid>
                <ShowShapes />
              </Grid>
            </div>
          )}
        </CardBody>
      </Card>


      {/*DIALOG INSERIMENTO FRAME*/}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={openShape}
        maxWidth="xl"
      >
        <DialogContent>
          <LoadedShape
            shape={shapeData}
            creation={creation}
            onHandleClose={handleClickClose}
            shapes={shapes}
            setShapes={setShapes}
          />
        </DialogContent>
      </Dialog>

      {/*DIALOG CANCELLA FRAME*/}
      <Dialog
        onClose={handleClickCloseDelete}
        aria-labelledby="customized-dialog-title"
        open={openDeleteShape}
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t("10241")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t("10242")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickCloseDelete}
            color="primary"
          >
            {t("10243")}
          </Button>
          <Button onClick={fetchDeleteShape} color="primary">
            {t("10244")}
          </Button>
        </DialogActions>
      </Dialog>


      {/* MESSAGGI DIALOGO */}
      <Dialog
        open={openDialog || isSaving}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        {openDialog ? (
          <Fragment>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogContent>
              <DialogContent>
                <CircularProgress size={42} style={{ color: "primary" }} />
              </DialogContent>
            </DialogContent>
          </Fragment>
        )}
      </Dialog>
    </div>
  )
}